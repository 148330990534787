import { Link } from "react-router-dom";
import Gradient from "./Gradient.js";

function InternalGradientLink(props) {
    return (
        <Gradient dir="left-to-right" from="#363795" to="#1d8fe1">
            <Link to={props.href}>
                {props.children}
            </Link>
        </Gradient>
    )

}

export default InternalGradientLink;

import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Nav from './components/Nav.js';
import Logo from './components/Logo.js';
import Markdown from 'markdown-to-jsx';
import { API_ROOT } from '../constants.js';

const customLink = ({ children, ...props }) => {
    return <a {...props} target='_blank'>{children}</a>;
};

function Post(props) {
    let { state } = useLocation();
    const params = useParams();
    let [currState, setCurrState] = useState(state);
    let [content, setContent] = useState('');
    let [data, setData] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (data == null) {
            fetch(`${API_ROOT}/pages_meta`).then(result => {
              return result.json();
            }).then((data) => {
                if (data) {
                  setData(data);
                }
            })
          }

        if (data && !currState) {
            setCurrState(data.in_progress.concat(data.pages).find(element => element.id == params.id));
        }

        if (currState) {
            document.title = currState.name;
            fetch(`${API_ROOT}/writing?id=${currState.id}`).then(result => {
                return result.json();
            }).then((data) => {
                if (data.data) {
                    setContent(data.data);
                }
            })
        }
    });
    
    return (
        <div>
        {currState && content != '' ?
        <div>
            <div className="nav-only">
                <Logo></Logo>
                <Nav></Nav>
            </div>
            <section className="section-page fill-page">
                <div className='page-title'>
                    {currState.name}
                </div>
                <br/>
                <div className='page-subtitle'>
                    <span>written by <strong>Anish Kachinthaya</strong> on {currState.date}</span>
                </div>
                <br/><br/>
                <div className="page-content">
                    
                    <Markdown className="page-text"
                        options={{
                            overrides: {
                                h1: {
                                    props: {
                                        className: "h1-page",
                                    },
                                },
                                img: {
                                    // component: customComponent,
                                    props: {
                                        className: 'img-page',
                                    },
                                },
                                a: {
                                    component: customLink,
                                    props: {
                                        className: 'a-page',
                                    },
                                },
                            },
                        }}>
                        {content}
                    </Markdown>
                    <br/>
                    <div className="center-child">
                        <Link to="/writing" className="return-btn">
                            Return to Writing
                        </Link>
                    </div>
                </div>
            </section>
            {/* <Footer succinct={true}></Footer> */}
        </div> 
        :
        ''
        }
        </div>
    );
};

export default Post;

import { useEffect } from 'react';
import { API_ROOT } from '../constants.js';

function Redirect(props) {
    useEffect(() => {
        if (!document.location.href.includes('test')) {
            fetch(`${API_ROOT}/register?page=${props.register}&referrer=${document.referrer}`);
        }
        window.location.replace(props.link)
    }, [])

    return null;
}
  
export default Redirect;
import { useEffect } from 'react';
import Footer from './components/Footer.js'
import Nav from './components/Nav.js'
import Logo from './components/Logo.js'
import { API_ROOT } from '../constants.js';
import { ExternalLink } from 'react-external-link';
import CanvasBG from './components/3d/CanvasBG.js';

function Contact() {
  useEffect(() => {
    document.title = 'Anish Kachinthaya | Contact';
    window.scrollTo(0, 0);

    if (!document.location.href.includes('test')) {
      fetch(`${API_ROOT}/register?page=contact&referrer=${document.referrer}`);
    }
  });

  return (
    <div>
      <header className="contact-header">
        <Logo></Logo>
        <Nav></Nav>

        <div className="contact-header-text-box">
          <div className="contact-box">
            <div className="contact-main-left">
              <h1 className="large-primary">Get in touch.</h1>

              <h3 className="contact-subheader">Have ideas for projects?</h3>
              <p className="contact-text">I'm always looking for the next exciting opportunity to learn, big or small.</p>
              
              <h3 className="contact-subheader">Have suggestions for my portfolio?</h3>
              <p className="contact-text">I'm constantly updating my portfolio with new projects and design ideas, and I'd love to hear any suggestions!</p>
              
              <h3 className="contact-subheader">Want to chat?</h3>
              <p className="contact-text">Drop your email and let's schedule a time to meet!</p>
            </div>

            <div className="contact-main">
              <h1 className="contact-primary">Contact</h1>
              <form action="https://formspree.io/f/xpzkwwgw" method="POST" className="formspree-form" autoComplete="off">
                <input required className="form-element" type="text" name="name" placeholder="Name" />
                <input required className="form-element" type="email" name="_replyto" placeholder="Email" />
                <input required className="form-element message-element" type="text" name="text" placeholder="Message" />
                <input className="form-element form-button" type="submit" value="Send" />
                <ExternalLink className="form-element form-button" href="https://calendly.com/anishkachinthaya">1-on-1</ExternalLink>
              </form>
            </div>
          </div>
        </div>
      </header>
      
      {/* <Footer succinct={true}></Footer> */}
    </div>
  );
}

export default Contact;

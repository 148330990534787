import React, { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Nav from './components/Nav.js';
import Logo from './components/Logo.js';
import Footer from './components/Footer.js';
import Markdown from 'markdown-to-jsx';
import { API_ROOT } from '../constants.js';

const customLink = ({ children, ...props }) => (
    <a {...props} target='_blank'>{children}</a>
);

function Project(props) {
    let { state } = useLocation();
    const params = useParams();
    let [currState, setCurrState] = useState(state);
    let [content, setContent] = useState('');
    let [data, setData] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (data == null) {
            fetch(`${API_ROOT}/projects_meta`).then(result => {
              return result.json();
            }).then((data) => {
                if (data) {
                  setData(data);
                }
            })
          }      

        if (data && !currState) {
            setCurrState(data.projects.find(element => element.id == params.projId));
        }

        if (data && currState) {
            document.title = currState.page.title;
            fetch(`${API_ROOT}/projects?projId=${currState.id}`).then(result => {
                return result.json();
            }).then((data) => {
                if (data.data) {
                    setContent(data.data);
                }
            })
        }
    });
    
    return (
        <div>
        {
        currState && content != '' ?
        <div>
            <div className="nav-only">
                <Logo></Logo>
                <Nav></Nav>
            </div>
            <section className="section fill-page">
                <div className='project-title'>
                    {currState.page.title}
                </div>
                <div className='project-subtitle'>
                    {currState.page.timeline}
                </div>

                <div className="project-content">
                    <div className="project-block project-block-left">
                        <br/>
                        <Markdown
                        options={{
                            overrides: {
                                a: {
                                    component: customLink,
                                },
                            },
                        }}>
                        {content}</Markdown>
                        <br/>
                        <Link to="/projects" className="return-btn">
                            Return to Projects
                        </Link>
                        <br/>
                        <br/>
                    </div>
                    <div className="project-block">
                        {currState.page.images ? 
                        <div className="img-flex-gallery">{currState.page.images.map((el, i) => {
                            let x = "flex-img";
                            if (currState.page.imagesize && currState.page.imagesize == "small") {
                                x = "small-flex-img";
                            }
                            return <img key={i} className={x} src={`${API_ROOT}${el}`}/>;
                        })}</div>
                        : null}
                        {currState.page.videos ? 
                        <div className="img-flex-gallery">{currState.page.videos.map((el, i) => 
                            <iframe key={i} className="flex-img" width="100%" height="600" src={el} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        )}</div>
                        : null}
                        {currState.page.pdfs ? 
                        <div className="img-flex-gallery">{currState.page.pdfs.map((el, i) => 
                            <embed key={i} className="flex-img" src={el} type='application/pdf' height="800"/>
                        )}</div>
                        : null}
                    </div>
                </div>
            </section>
            <Footer succinct={true}></Footer>
        </div> 
        :
        ''
        }
        </div>
    );
};

export default Project;

import { ExternalLink } from "react-external-link";
import { useEffect } from "react";

import Nav from "./components/Nav.js";
import Logo from "./components/Logo.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { API_ROOT } from "../constants.js";

import CanvasBG from "./components/3d/CanvasBG.js";
import ExternalGradientLink from "./components/ExternalLink.js";
import InternalGradientLink from "./components/InternalLink.js";


function Home() {
  useEffect(() => {
    document.title = "Anish Kachinthaya | Home";
    window.scrollTo(0, 0);

    if (!document.location.href.includes("test")) {
      fetch(`${API_ROOT}/register?page=home&referrer=${document.referrer}`);
    }
  });

  return (
    <div>
      <div className="header">
        <Logo></Logo>
        <Nav></Nav>

        <div className="header-text-box">
          <div className="hello-text header-text-home dark">
            Hi, I'm Anish.
          </div>
          <p>
            I'm an EECS MS student at UC Berkeley in the <ExternalGradientLink href="https://bair.berkeley.edu/">BAIR</ExternalGradientLink>
            {" "}lab. I recently graduated from{" "}
            <ExternalGradientLink href="https://met.berkeley.edu/">Berkeley MET</ExternalGradientLink>
            {" "}with a BS in EECS and Business.
            I'm excited about data-driven applications, machine learning,
            operating systems, and distributed computing. Within ML, I'm
            interested in multimodal, representation learning, and latent
            spaces.
            <br />
            <br />
            I am currently working on AI-powered search at{" "}
            <ExternalGradientLink href="https://www.glean.com/">Glean</ExternalGradientLink>
            .{" "}At Berkeley, I research in vision-language at the{" "}
            <ExternalGradientLink href="https://darrellgroup.github.io/">Darrell Group</ExternalGradientLink>
            , most recently with detecting hallucinations in image captioning
            models. Here, I've worked with many popular models such
            as CLIP, LLaMA, BLIP, MDETR, and LLaVA. I am also a{" "}
            <ExternalGradientLink href="https://neo.com/">Neo Scholar</ExternalGradientLink>
            {" "}and a{" "}
            <ExternalGradientLink href="https://fellows.kleinerperkins.com/">KP Fellow</ExternalGradientLink>.
            <br />
            <br />
            <h3>Previously,</h3>
            <ul>
              <li>
                <ExternalGradientLink href="https://nvidia.com/">NVIDIA</ExternalGradientLink>
                {" "}distributed systems and data governance for the{" "}
                <ExternalGradientLink href="https://www.nvidia.com/en-us/data-center/swiftstack/">Swift</ExternalGradientLink>
                {" "}data platform
              </li>
              <li>
                <ExternalGradientLink href="https://palantir.com/">Palantir</ExternalGradientLink>
                {" "}B2B Cloud{" "}
                <ExternalGradientLink href="https://www.palantir.com/offerings/fedstart/">FedStart</ExternalGradientLink>
                {" "}offering, built on{" "}
                <ExternalGradientLink href="https://blog.palantir.com/introducing-rubix-kubernetes-at-palantir-ab0ce16ea42e">Rubix</ExternalGradientLink>
              </li>
              <li>
                <ExternalGradientLink href="https://ml.berkeley.edu/">Machine Learning at Berkeley</ExternalGradientLink>
                {" "}VP of Projects, solving real-world ML problems for industry clients
              </li>
              <li>
                <ExternalGradientLink href="https://calhacks.io/">Cal Hacks</ExternalGradientLink>
                {" "}Director, hosting hello:world, 8.0, and 9.0
              </li>
              <li>
                <ExternalGradientLink href="https://www.lucidmotors.com/">Lucid Motors</ExternalGradientLink>
                {" "}building data infra, ML pipelines for 2D & 3D object detection, and auto-labeling models
              </li>
              <li>
                <ExternalGradientLink href="https://cs61c.org/fa22/">CS 61C</ExternalGradientLink>
                {" "}teaching computer architecture,
                memory management, and parallelism in C
              </li>
              <li>
                <ExternalGradientLink href="https://llnl.gov/">LLNL</ExternalGradientLink>
                {" "}developing data pipelines for nuclear film analysis
              </li>
            </ul>
          </p>
          <p>
            Feel free to{" "}
            <InternalGradientLink href="contact">reach out</InternalGradientLink>
            {" "}to me and I'd love to chat!
          </p>

          <p className="media-links">
            <ExternalLink
              href="https://twitter.com/anishk__"
              data-umami-event="Home Twitter"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </ExternalLink>

            <ExternalLink
              href="https://www.linkedin.com/in/anish-kachinthaya/"
              data-umami-event="Home LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </ExternalLink>

            <ExternalLink
              href="https://github.com/anishk23733/"
              data-umami-event="Home Github"
            >
              <FontAwesomeIcon icon={faGithub} />
            </ExternalLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;

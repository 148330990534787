import { Link } from "react-router-dom";

function PageItem(props) {
    return (
        <Link className="page-item" to={`/writing/${props.id}`} state={props}
                id={props.id} data-umami-event={props.name}>
            <div className="page-item-name">{props.name}</div>
            <div className="page-item-bottom">
                <span>{props.date}</span>
                <span>{props.time}</span>
                {/* <span>{props.time} | {props.views} views</span> */}
            </div>
        </Link>
    )
}

export default PageItem;

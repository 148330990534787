import { Link } from "react-router-dom";

function Nav(props) {
    return (
        <nav className="nav">
            <ul className="nav-ul">
                <li className="nav-item">
                    <Link className={`nav-link`} to='/projects'>Projects</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link`} to='/writing'>Writing</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link`} to='/contact'>Contact</Link>
                </li>
            </ul>
        </nav>
    )

}

export default Nav;

import { useEffect, useState } from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import PostItem from './components/PostItem.js';
import { API_ROOT } from '../constants.js';

function Writing() {
  let [data, setData] = useState(null);

  useEffect(() => {
    document.title = 'Anish Kachinthaya | Writing';
    window.scrollTo(0, 0);

    if (!document.location.href.includes('test')) {
      fetch(`${API_ROOT}/register?page=writing&referrer=${document.referrer}`);
    }

    if (data == null) {
      fetch(`${API_ROOT}/pages_meta`).then(result => {
        return result.json();
      }).then((data) => {
          if (data) {
            setData(data);
          }
      })
    }
  });

  return (
    <div>
      {data ?
      <div>
        <Header 
          content="Writing"
          bgcolor='#fff' headertype='page'>
        </Header>
        <section className="section-narrow">
            <p>The following are bits and pieces of my thoughts on computing, machine learning, and life.</p>
            {(window.location.href.includes('test')) ? data.in_progress.map((el, i) => 
              <PostItem key={el.id} {...el} views={data.views[el.id]}></PostItem>
            ) : null}
            {data.pages.map((el, i) => 
              <PostItem key={el.id} {...el} views={data.views[el.id]}></PostItem>
            )}
        </section>
        <Footer succinct={false} ></Footer>
      </div>
      : null}
    </div>
  );
}

export default Writing;

import { Link } from "react-router-dom";

function Header(props) {
    return (
        <div className='logo'>
            <Link className={`logo-text`} to='/'>Anish Kachinthaya</Link>
        </div>
    )

}

export default Header;

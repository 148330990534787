import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiagramProject } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";
import { ExternalLink } from 'react-external-link';

function Footer(props) {
    return (
        <footer className="footer">
            <div className="footer-container">
                <p className="media-links">
                    <ExternalLink href='https://twitter.com/anishk__' data-umami-event="Footer Twitter"> 
                        <FontAwesomeIcon icon={ faTwitter } /> Twitter
                    </ExternalLink>

                    <ExternalLink href='https://www.linkedin.com/in/anish-kachinthaya/' data-umami-event="Footer LinkedIn"> 
                        <FontAwesomeIcon icon={ faLinkedin } /> LinkedIn
                    </ExternalLink>

                    <ExternalLink href='https://github.com/anishk23733/' data-umami-event="Footer Github">
                        <FontAwesomeIcon icon={ faGithub } /> Github
                    </ExternalLink>
                </p>

                <p className="credits">Coded with ❤️ by Anish Kachinthaya</p>
            </div>
        </footer>
    )

}

export default Footer;
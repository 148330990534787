import { Link } from "react-router-dom";
import { API_ROOT } from '../../constants.js';

function ProjectItem(props) {
    return (
        <Link className="project-item w" to={`/projects/${props.id}`} state={props}
             id={props.id} data-umami-event={props.name}>
            <div className="top">
                <img className="thumbnail-image" src={`${API_ROOT}${props.thumbnail}`} />
            </div>
            <div className="bottom">
                <div className="bottom-primary">
                    {props.name}
                </div>
                <div className="bottom-secondary">{props.affiliation}</div>
            </div>
        </Link>

    )
}

export default ProjectItem;

// @refresh reset
import React, { useEffect, useState, useRef } from 'react';
import Footer from '../components/Footer.js';
import Nav from '../components/Nav.js';
import Logo from '../components/Logo.js';
import { API_ROOT } from '../../constants.js';
import MIDISounds from 'midi-sounds-react';
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faCircleStop } from '@fortawesome/free-solid-svg-icons'

// sounds https://surikov.github.io/midi-sounds-react-examples/examples/midi-sounds-example3/build/

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: `s--- p--- s"--- p--- s--- p'--- s---` }],
  },
  {
    type: 'paragraph',
    children: [{ text: `n' s r g m p d n s"-- s" .. [n d p] m g [r s] n' d' [n' [s r s]]` }],
  },
  {
    type: 'paragraph',
    children: [{ text: `` }],
  },
  {
    type: 'paragraph',
    children: [{ text: `!remove or add lines <-- this is a comment` }],
  },
]

const pitchOptions = [
  {
    label: "C",
    value: "C",
  },
  {
    label: "C#",
    value: "Cs",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "D#",
    value: "Ds",
  },
  {
    label: "E",
    value: "E",
  },
  {
    label: "F",
    value: "F",
  },
  {
    label: "F#",
    value: "Fs",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "G#",
    value: "Gs",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "A#",
    value: "As",
  },
  {
    label: "B",
    value: "B",
  },
];

const raagaOptions = [
  {
    label: "Bhairav",
    value: "Bhairav",
  },
  {
    label: "Rageshree",
    value: "Rageshree",
  },
];

function Player() {
  let midiRef = useRef(null);
  let [instrument, setInstrument] = useState(1120); // 1120 to 1128 sitar, 211 - 222 Accordion
  const editorRef = useRef();
  if (!editorRef.current) editorRef.current = withReact(createEditor());
  const editor = editorRef.current;
  let [value, setValue] = useState(initialValue);
  let [bpm, setBPM] = useState(160);
  let [pitch, setPitch] = useState('Cs');
  let [raaga, setRaaga] = useState('Bhairav');

  useEffect(() => {
    document.title = 'Anish Kachinthaya | Music';
    window.scrollTo(0, 0);

    fetch(`${API_ROOT}/register?page=music`);
  });

  let playInstrument = () => {
    if (midiRef == null) {
      return;
    }
    midiRef.setEchoLevel(.4);
    midiRef.cancelQueue();

    fetch(`${API_ROOT}/music?data=${JSON.stringify(value)}&bpm=${bpm}&pitch=${pitch}&raaga=${raaga}`).then(result => {
      return result.json();
    }).then((data) => {
        if (data) {
          data.data.forEach(el => {
            midiRef.playChordAt(midiRef.contextTime() + el.time, instrument, el.pitch, el.duration);
            // setTimeout(() => {console.log(el.note);}, 500 + el.time * 1000);
          })
        }
    })
	}

  let stopInstrument = () => {
    if (midiRef == null) {
      return;
    }
    midiRef.cancelQueue(); 
  }

  return (
    <div>
      <div className="nav-only">
          <Logo></Logo>
          <Nav></Nav>
      </div>
      <div className="section fill-page">
        <div className='project-title'>
            Hindustani Sargam Player
        </div>
        <p>
          Select a BPM, pitch, and Raaga to get started.
        </p>
        <p className="player-alert">
          This player is currently only supported on desktop web browsers :(
        </p>
        <div className='controls-row'>
          <span className="play-btn" onClick={playInstrument}><FontAwesomeIcon icon={ faCirclePlay } /></span>
          <span className="stop-btn" onClick={stopInstrument}><FontAwesomeIcon icon={ faCircleStop } /></span>
          <input className="control" type='text' value={bpm} onChange={(val) => {setBPM(val.target.value)}}/>
          <select className="control" value={pitch} onChange={val => {setPitch(val.target.value)}}>
            {pitchOptions.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
          </select>
          <select className="control" value={raaga} onChange={val => {setRaaga(val.target.value)}}>
            {raagaOptions.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
          </select>
        </div>
        <div className="editor">
          <Slate editor={editor} value={value} onChange={setValue}>
            <Editable />
          </Slate>
        </div>
        <MIDISounds ref={(ref) => (midiRef = ref)} appElementName="root" instruments={[instrument]} />
      </div>
      
      <Footer succinct={true}></Footer>
    </div>
  );
}

export default Player;

import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/fonts.css';
import './styles/style.scss';

import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import Home from './pages/Home.js';
import Projects from './pages/Projects.js';
import Contact from './pages/Contact.js';
import Writing from './pages/Writing.js';
import Project from './pages/Project.js';
import Post from './pages/Post.js';
import Redirect from './pages/Redirect.js';
import Admin from './pages/Admin.js';
import Player from './pages/custom/Player.js';

import { ZOOM, CALENDLY } from './constants.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="*" element={<Home />} />
          
          <Route path="/projects">
            <Route path=":projId" element={<Project />} />
            <Route index element={<Projects />} />
          </Route>

          <Route path="/writing">
            <Route path=":id" element={<Post />} />
            <Route index element={<Writing />} />
          </Route>

          <Route path="/contact" element={<Contact />} />
          <Route path='/zoom' element={<Redirect register='zoom' link={ZOOM}/>}/>
          <Route path='/meet' element={<Redirect register='meet' link={CALENDLY}/>}/>
          <Route path='/player' element={<Player/>}/>


          <Route path='/admin' element={<Admin />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

import { useEffect, useState } from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import ProjectItem from './components/ProjectItem.js';

import { API_ROOT } from '../constants.js';
import { Link } from "react-router-dom";

function Projects() {
  let [data, setData] = useState(null);

  useEffect(() => {
    document.title = 'Anish Kachinthaya | Projects';
    window.scrollTo(0, 0);

    if (!document.location.href.includes('test')) {
      fetch(`${API_ROOT}/register?page=projects&referrer=${document.referrer}`);
    }

    if (data == null) {
      fetch(`${API_ROOT}/projects_meta`).then(result => {
        return result.json();
      }).then((data) => {
          if (data) {
            setData(data);
          }
      })
    }
  });

  return (
    <div>
      {data ? 
      <div>
      <Header 
        content="Projects"
        bgcolor='#fff'
        headertype='page'>
      </Header>

      <section className="section">
        <div className="title-box"> 
          <h2 className="distinct-blue-text">Showcase</h2>
        </div>
        <br/>
        <div className="project-row">
          {data.projects.map((el, i) => 
            <ProjectItem key={i} {...el}></ProjectItem>
          )}
        </div>

        <br/><br/><br/>
            <h2 className="larger-h2 distinct-blue-text">Tools</h2>
            <br />
            <ul>
              <li><strong>Semantic Search</strong> search a text for conceptually similar concepts <a target="_blank" href="https://github.com/anishk23733/semantic_search">source</a></li>
              <li><strong>Altr</strong> alt text generator for static websites to improve SEO and accessibility <a target="_blank" href="https://github.com/anishk23733/altr">source</a></li>
              <li><strong>Hindustani Music Player</strong> music player that converts Hindustani Sargam notes into MIDI pitches and plays them in the browser <Link to="/player">player</Link></li>
              <li><strong>Astro</strong> (Songwriting App) <a href="https://astro.anishkachinthaya.com">site</a> <a href="https://github.com/anishk23733/astro">source</a></li>
              <li>GaelScout (VEX Robotics Scouting App) <a target="_blank"
                href="http://bit.ly/GaelScoutDownloads">download</a> <a target="_blank"
                href="https://github.com/anishk23733/vexneuralnet">source</a></li>
            </ul>
            <br/><br/><br/>
            <h2 className="larger-h2 distinct-blue-text">Research</h2>
            <br />
            <p>
              Computer Vision (Object Pixel Masking) <a target="_blank" href="https://ojs.aaai.org//index.php/AAAI/article/view/7189">paper</a>
            </p>

      </section>

      <Footer succinct={false} ></Footer>
      </div>
      : null}
    </div>
  );
}

export default Projects;

import Nav from './Nav.js';
import Logo from './Logo.js';

function Header(props) {
    return (
        <div className="header-page" style={{backgroundImage: props.bgcolor}}>
            <Logo txtstyle={props.txtstyle}></Logo>
            <Nav txtstyle={props.txtstyle}></Nav>
            
            <div className="header-text-box-page">
                <div className={`header-text ${props.txtstyle}`}>
                    {props.content}
                </div>
            </div>
        </div>
    )

}

export default Header;
